import logo from '../img/logo.png';

const Logo = () => {
    return(
        <a className="logo" href="#">
            <img alt="Foolstack Studio: Web Agency creativa a Milano" src={logo} />
        </a>
    );
}

export default Logo;
