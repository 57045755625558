import './App.css';
import bg from './img/bg.png';
import Logo from "./components/Logo";
import Title from "./components/Title";
import Paragraph from "./components/Paragraph";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App" style={{ backgroundImage:`url(${bg})` }}>
        <div className="wrapper">
            <Logo />
            <Title />
            <Paragraph />
            <Footer />
        </div>
    </div>
  );
}

export default App;
