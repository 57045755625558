const Paragraph = () => {
    return(
        <p className="paragraph">
            Sito in costruzione.<br />Saremo online a breve.<br />
            Per informazioni o preventivi<br />scrivici a <a href="mailto:info@foolstack.it">info@foolstack.it</a>
        </p>
    );
}

export default Paragraph;
