const Title = () => {
    return(
        <div className="title">
            <h1>Fool<span className="text-gradient text-bold">stack</span></h1>
            <h2>Web Agency creativa a Milano</h2>
        </div>
    );
}

export default Title;
